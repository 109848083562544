import { throttle } from 'lodash';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

const MOS = {};
window.MOS = MOS;
(() => {
    const SCREENMDMAX = 1199;
    let winSize = 0;
    const calcWinSize = () => {
        winSize = { width: window.innerWidth, height: window.innerHeight };
    };
    const scrollTo = (elt, duration, target) => {
        console.log('scrollto', elt, duration, target);
        gsap.to(
            window,
            {
                duration: duration || 1,
                scrollTo: elt.getBoundingClientRect().top + window.scrollY,
                onComplete: () => {
                    if (target !== undefined) {
                        // window.location.hash = target;
                    }
                },
            },
        );
    };

    const scrollToId = (href) => {
        if (href !== undefined) {
            const target = href.split('#')[1];
            if (document.getElementById(target) !== null) {
                scrollTo(document.getElementById(target), 1, target);
            }
        }
    };

    class MainNav {
        constructor(elt, eltWrapper) {
            if (document.querySelector(elt) && document.querySelector(eltWrapper)) {
                const self = this;
                self.DOM = { elt: document.querySelector(elt) };
                self.DOM.eltWrapper = document.querySelector(eltWrapper);
                self.settings = self.DOM.elt.hasAttribute('data-settings') ? JSON.parse(self.DOM.elt.getAttribute('data-settings')) : {};
                self.DOM.openBtn = self.DOM.eltWrapper.querySelector('.btn-burger');
                self.DOM.closeBtn = self.DOM.eltWrapper.querySelector('.btn-close');
                this.DOM.menuLinks = this.DOM.elt.querySelectorAll('.menu__item a');
                self.isOpen = false;
                self.initEvents();
            }
        }
        initEvents() {
            const self = this;
            if (self.DOM.openBtn) {
                self.DOM.openBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.isOpen) {
                        this.toggle('close');
                    } else {
                        this.toggle('open');
                    }
                });
            }

            if (self.DOM.closeBtn) {
                self.DOM.closeBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.toggle('close');
                });
            }

            self.menuItems = [];
            Array.from(self.DOM.elt.querySelectorAll('.menu__item--ddm')).forEach(item => this.menuItems.push(new DDMenuItem(item)));

            if (self.settings.sticky !== undefined && self.settings.sticky && winSize.width > SCREENMDMAX) {
                window.addEventListener('scroll', throttle(self.onScroll.bind(self), 500, { leading: true, trailing: true }));
            }

            self.DOM.menuLinks.forEach((elt) => {
                elt.addEventListener('click', (e) => {
                    const href = e.currentTarget.getAttribute('href');
                    const target = href.split('#')[1];
                    if (document.getElementById(target) !== null) {
                        MOS.mainNav.close();
                    }
                });
            });
        }

        toggle(action, _self) {
            const self = _self || this;
            self.isOpen = action === 'open';

            if (self.isOpen) {
                self.open();
            } else {
                self.close()
            }
        }

        open() {
            this.DOM.elt.classList.add('is-open');
            this.DOM.openBtn.classList.add('is-active');
            this.isOpen = true;
        }

        close() {
            this.DOM.elt.classList.remove('is-open');
            this.DOM.openBtn.classList.remove('is-active');
            this.isOpen = false;
        }

        onScroll(e) {
            const windowScrollY = window.scrollY;
            //200
            if (windowScrollY > 50) {
                this.DOM.elt.classList.add('is-sticky');
                this.DOM.isSticky = true;
            } else {
                this.DOM.elt.classList.remove('is-sticky');
                this.DOM.isSticky = false;
            }
            // console.log('StickyNav::scrolling', windowScrollY);
        }
    }
    
    function initEvents() {
        // if (document.querySelectorAll('.video-wrapper').length > 0) {
        //     document.querySelectorAll('.video-wrapper').forEach((elt) => {
        //         const videoWrapper = elt;
        //         const video = elt.querySelector('video')[0];
        //         const btnPlay = videoWrapper.querySelector('.btn-play');

        //         video.addEventListener('pause', function() {
        //             videoWrapper.querySelector('.svg-pause').classList.add('svg-play');
        //             videoWrapper.querySelector('.svg-pause').classList.remove('svg-pause');
        //             video.setAttribute('data-state', 'play');
        //         });
    
        //         video.addEventListener('ended', function() {
        //             videoWrapper.querySelector('.svg-pause').classList.add('svg-play');
        //             videoWrapper.querySelector('.svg-pause').classList.remove('svg-pause');
        //             video.setAttribute('data-state', 'ended');
        //         });
    
        //         video.addEventListener('play', function() {
        //             videoWrapper.querySelector('.svg-play').classList.add('svg-pause');
        //             videoWrapper.querySelector('.svg-play').classList.remove('svg-play');
        //             video.setAttribute('data-state', 'pause');
        //         });
    
        //         btnPlay.addEventListener('click', function(e) {
        //             const vid = e.target.siblings('video')[0];
        //             if (vid.paused || vid.ended) {
        //                 vid.play();
        //              } else {
        //                 vid.pause();
        //              }
        //              console.log(vid);
        //         });
        //     })
            
        // }
    }
    
    function init() {
        MOS.scrollPlugin = ScrollToPlugin;
        gsap.registerPlugin(ScrollToPlugin);
        MOS.mainNav = new MainNav('.c-main-nav', '.c-main-nav__wrapper');

        // hijack link with anchors & scroll to
        document.querySelectorAll('a[href^="#"]').forEach((elt) => {
            elt.addEventListener('click', (e) => {
                e.preventDefault();
                const href = e.currentTarget.getAttribute('href');
                if (href !== undefined) {
                    const target = href.split('#')[1];
                    if (document.getElementById(target) !== null) {
                        scrollTo(document.getElementById(target));
                    }
                }
            });
        });

        if (document.querySelector('.validation-summary-errors')) {
            scrollTo(document.querySelector('.validation-summary-errors'));
          }
      
          if (document.querySelector('.validation-summary-success')) {
            scrollTo(document.querySelector('.validation-summary-success'));
          }

        initEvents();
    }

    document.addEventListener('DOMContentLoaded', () => {
        calcWinSize();
        init();
    });
})();